import React from "react"

import Logo from "../assets/images/logo.png"

import { Link } from "react-router-dom"

import "../assets/scss/components/_header.scss"

export const Header = () => {
	return (
		<section className="header">
			<div className="row">
				<div className="col-4 navigation-links">
					{/* <Link>About</Link> */}
					{/* <Link>Projects</Link> */}
					{/* <Link>Contact</Link> */}
				</div>

				<div className="col-4 logo">
					<Link to="/">
						<img
							src={Logo}
							alt="Olly Pittaway"
						/>
					</Link>
				</div>

				<div className="col-4 social-links"></div>
			</div>
		</section>
	)
}
