import React from "react"

import "../assets/scss/components/_site-preview.scss"

export const SitePreview = ({ name, url, background, role, pills }) => {
	return (
		<a
			className="site-preview"
			href={url}
			target="_blank"
		>
			<div className="background">
				<img
					src={background}
					alt={name}
					className="backing-image"
				/>
			</div>
			<div className="company-details">
				<strong className="site-name">{name}</strong>
				<strong className="role">{role}</strong>
				<div className="pills">
					{pills.map((pill, index) => {
						return (
							<span
								key={index}
								className="pill"
							>
								{pill}
							</span>
						)
					})}
				</div>
			</div>
		</a>
	)
}
