import React from "react"

import "../assets/scss/components/_home-hero.scss"

import { Link } from "react-router-dom"

export const HomeHero = () => {
	return (
		<section className="home-hero">
			<div className="container-small">
				<div className="text-intro">
					<div className="quote">Lead Front-End Web Developer</div>
					<h1>Producer of websites for some of the UK's largest brands</h1>
					<p className="subtitle">A UK based web developer with a strong eye for details. Providing branding, web designs and development of the highest standard.</p>
					{/* <Link
						to="/projects"
						className="btn"
					>
						Explore The Projects
					</Link> */}
				</div>
			</div>
		</section>
	)
}
