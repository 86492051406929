import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from "react-router-dom"

import { Home } from "../pages"

export const RoutesComponent = ({ RootElement }) => {
	return createBrowserRouter(
		createRoutesFromElements(
			<Route
				path="/"
				element={<RootElement />}
			>
				{/* <Route
					path="/projects"
					element={<AllProjects />}
				/> */}
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="*"
					element={<Navigate to="/" />}
				/>
			</Route>
		)
	)
}
