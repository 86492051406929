import React, { useRef, useEffect, useState } from "react"
import "../assets/scss/components/_carousel.scss"

export const Carousel = ({ slides, prefix = "carousel", slide_duration = 5 }) => {
	const carouselRef = useRef(null)
	const [activeSlides, setActiveSlides] = useState([...slides, ...slides, ...slides])
	const [slidesPerRow, setSlidesPerRow] = useState(1)
	const scrollSpeed = 1 // Adjusted for gradual change
	const frameRate = 20 // 20ms for smoother animation

	useEffect(() => {
		let animationFrameId

		const getSlidesPerRow = () => {
			const computedStyle = getComputedStyle(document.documentElement)
			return parseInt(computedStyle.getPropertyValue("--slides-per-row"), 10) || 1
		}

		const updateSlidesPerRow = () => {
			const newSlidesPerRow = getSlidesPerRow()
			setSlidesPerRow(newSlidesPerRow)
		}

		const animateScroll = () => {
			if (carouselRef.current) {
				const container = carouselRef.current

				const slideWidth = slides.length * document.querySelector(".carousel-slide").offsetWidth

				container.scrollLeft += scrollSpeed

				const resetPoint = slideWidth * 2

				// Reset the scroll position to start when it reaches the end of second duplicate
				if (container.scrollLeft >= resetPoint) {
					container.scrollLeft = slideWidth
				}

				animationFrameId = requestAnimationFrame(animateScroll)
			}
		}

		const startAnimation = () => {
			animationFrameId = requestAnimationFrame(animateScroll)
		}

		updateSlidesPerRow()
		window.addEventListener("resize", updateSlidesPerRow)

		startAnimation()
		return () => {
			cancelAnimationFrame(animationFrameId)
			window.removeEventListener("resize", updateSlidesPerRow)
		}
	}, [slides.length, slidesPerRow])

	return (
		<div
			className={`carousel-container ${prefix !== "carousel" && `${prefix}-container`}`}
			ref={carouselRef}
			style={{
				"--slides-per-row": slidesPerRow
			}}
		>
			{activeSlides.map((slide, index) => (
				<div
					className={`carousel-slide ${prefix !== "carousel" && `${prefix}-slide`}`}
					key={prefix + index}
				>
					{slide.element}
				</div>
			))}
		</div>
	)
}
