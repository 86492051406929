import React from "react";

import { Carousel, ContactForm, HomeHero, SitePreview, StatsSection, Skillset, StandoutProject, WorkedWith } from "./../components";

import HauteImage from "./../assets/images/site-previews/small/haute.webp";
import PrestigeImage from "./../assets/images/site-previews/small/prestige.webp";
import PrestigeHampersImage from "./../assets/images/site-previews/small/prestige-hampers.webp";
import NationalGalleryImage from "./../assets/images/site-previews/small/national-gallery.webp";
import MonsoonImage from "./../assets/images/site-previews/small/monsoon.webp";
import CrukImage from "./../assets/images/site-previews/small/cruk.webp";
import BmImage from "./../assets/images/site-previews/small/bm.webp";
import HbImage from "./../assets/images/site-previews/small/hb.webp";

export const Home = () => {
	return (
		<div className="home">
			<HomeHero />

			<Carousel
				slides={[
					{
						element: (
							<SitePreview
								background={HauteImage}
								name="Haute Florist"
								url="https://www.hauteflorist.co.uk"
								role="Developer"
								pills={["Design", "Branding", "Development"]}
							/>
						)
					},
					{
						element: (
							<SitePreview
								background={PrestigeImage}
								name="Prestige Flowers"
								url="https://www.prestigeflowers.co.uk"
								role="Project Lead"
								pills={["Management", "Development"]}
							/>
						)
					},
					{
						element: (
							<SitePreview
								background={NationalGalleryImage}
								name="National Gallery Flowers"
								url="https://www.nationalgalleryflowers.co.uk"
								role="Developer"
								pills={["Design", "Development"]}
							/>
						)
					},
					{
						element: (
							<SitePreview
								background={PrestigeHampersImage}
								name="Prestige Hampers"
								url="https://www.prestigehampers.co.uk"
								role="Project Lead"
								pills={["Management", "Development"]}
							/>
						)
					},
					{
						element: (
							<SitePreview
								background={MonsoonImage}
								name="Monsoon Flowers"
								url="https://www.monsoonflowers.co.uk"
								role="Developer"
								pills={["Design", "Development"]}
							/>
						)
					},
					{
						element: (
							<SitePreview
								background={CrukImage}
								name="Cancer Research UK Flowershop"
								url="https://flowershop.cancerresearchuk.org/"
								role="Developer"
								pills={["Design", "Development"]}
							/>
						)
					},
					{
						element: (
							<SitePreview
								background={BmImage}
								name="B&M Flowers"
								url="https://www.bmflowers.co.uk"
								role="Developer"
								pills={["Development"]}
							/>
						)
					},
					{
						element: (
							<SitePreview
								background={HbImage}
								name="Home Bargains Flowers"
								url="https://www.homebargainsflowers.co.uk"
								role="Project Lead"
								pills={["Management", "Development"]}
							/>
						)
					}
				]}
				prefix="review-slider"
				arrows={true}
				slides_per_row={5.5}
				infinite={true}
			/>

			<StatsSection />

			<Skillset />

			<WorkedWith />

			<ContactForm />

			{/* <StandoutProject /> */}
		</div>
	);
};
