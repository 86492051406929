import React from "react"

import { Carousel, Slider } from "."

import BarcaImage from "./../assets/images/company-logos/barca.webp"
import BarnardosImage from "./../assets/images/company-logos/barnardos.webp"
import BmImage from "./../assets/images/company-logos/bm.webp"
import CrukImage from "./../assets/images/company-logos/cruk.webp"
import E3Image from "./../assets/images/company-logos/e3.webp"
import ExpectImage from "./../assets/images/company-logos/expect.webp"
import GipsilImage from "./../assets/images/company-logos/gipsil.webp"
import HauteImage from "./../assets/images/company-logos/haute.webp"
import HbImage from "./../assets/images/company-logos/hb.svg"
import MonsoonImage from "./../assets/images/company-logos/monsoon.webp"
import NationalGalleryImage from "./../assets/images/company-logos/nationalgallery.webp"
import PfImage from "./../assets/images/company-logos/pf-black.svg"

import "../assets/scss/components/_worked-with.scss"

export const WorkedWith = () => {
	return (
		<section className="worked-with">
			<div className="container">
				<strong className="h3-style">Companies I've Produced Work For</strong>
				<p>I have worked with quite a wide range of companies, from small startups to large corporations. Here are some of the most notable clients that I have worked with to date.</p>
				<Slider
					slides={[
						{
							element: (
								<img
									src={NationalGalleryImage}
									alt="National Gallery"
								/>
							)
						},
						{
							element: (
								<img
									src={BmImage}
									alt="B&M"
								/>
							)
						},
						{
							element: (
								<img
									src={MonsoonImage}
									alt="Monsoon"
									className="invert"
								/>
							)
						},
						{
							element: (
								<img
									src={CrukImage}
									alt="Cancer Research UK"
								/>
							)
						},
						{
							element: (
								<img
									src={HauteImage}
									alt="Haute Florist"
								/>
							)
						},
						{
							element: (
								<img
									src={HbImage}
									alt="Home Bargains"
								/>
							)
						},
						{
							element: (
								<img
									src={BarcaImage}
									alt="Barca"
								/>
							)
						},
						{
							element: (
								<img
									src={BarnardosImage}
									alt="Barnardos"
								/>
							)
						},
						{
							element: (
								<img
									src={E3Image}
									alt="E3"
								/>
							)
						},
						{
							element: (
								<img
									src={ExpectImage}
									alt="Expect"
								/>
							)
						},
						{
							element: (
								<img
									src={GipsilImage}
									alt="Gipsil"
								/>
							)
						},
						{
							element: (
								<img
									src={PfImage}
									alt="Prestige Flowers"
								/>
							)
						}
					]}
					prefix="worked-with-carousel"
					autoplay={true}
					slides_per_row={6}
					infinite={true}
				/>
			</div>
		</section>
	)
}
