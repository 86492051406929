import React from "react"

import "../assets/scss/components/_contact-form.scss"

import { Link } from "react-router-dom"

export const ContactForm = () => {
	return (
		<section className="contact-form">
			<div className="container-small">
				<span className="title heading-font">Reach Out To Me</span>
				<p>I have developed the perfect solution for some of the UK's largest brands. Whether you're looking for a quick chat over a coffee or to sit down and get straight to business let's see how I can help you.</p>

				<div className="ctas">
					{/* <button className="btn">To The Contact Page</button> */}
					<Link
						to="mailto:olly@pittaway.dev"
						className="btn inverted"
					>
						Send An Email
					</Link>
				</div>
			</div>
		</section>
	)
}
