import React, { useRef, useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"

import "../assets/scss/components/_slider.scss"

export const Slider = ({ slides, prefix = "slider", slides_per_row = 1, slide_duration = 5, arrows = false, indicators = false, autoplay = false, draggable = false, fade = false }) => {
	const [currentIndex, setCurrentIndex] = useState(0)

	let interval = null
	useEffect(() => {
		if (autoplay === true) {
			setInterval(() => {
				let maxIndex = Math.ceil(slides.length / slides_per_row - 1)
				setCurrentIndex((prevState) => {
					return prevState == maxIndex ? 0 : prevState + 1
				})
			}, slide_duration * 1000)
		}
	}, [])

	const [isDragging, setIsDragging] = React.useState(false)
	const [startDragPos, setStartDragPos] = React.useState(0) //for touch events only
	const [dragPos, setDragPos] = React.useState(0)
	const firstElement = useRef(null)

	const handleDrag = (e) => {
		if (draggable && isDragging) {
			var pos = dragPos + e.movementX
			pos = pos < 0 ? pos : 0

			if (e.movementX < 0) {
				//if moving right
				let size = firstElement.current.offsetWidth //get width of slider
				let hiddenSlides = slides.length - slides_per_row
				let max = hiddenSlides * size

				pos = pos * -1 > max ? max * -1 : pos
			}

			setDragPos(pos)
		}
	}

	draggable = slides.length > Math.floor(slides_per_row) ? draggable : false //dont make draggable if nothing to drag
	arrows = slides.length > Math.floor(slides_per_row) ? arrows : false
	indicators = slides.length > Math.floor(slides_per_row) ? indicators : false

	const handleMouseDown = (e) => {
		if (draggable) {
			setIsDragging(true)
		}
	}

	const handleMouseUp = (e) => {
		if (draggable) {
			setIsDragging(false)
		}
	}

	const handleTouchStart = (e) => {
		console.log(e.touches)

		if (draggable) {
			setStartDragPos(e.touches[0].clientX)
			setIsDragging(true)
		}
	}

	const handleTouchEnd = (e) => {
		if (draggable) {
			setIsDragging(false)
		}
	}

	const handleTouchDrag = (e) => {
		if (draggable && isDragging) {
			console.log("current", dragPos, "touch", e.touches[0].clientX)

			let rateControl = 0.05

			let pos = (e.touches[0].clientX - startDragPos) * rateControl + dragPos
			console.log("momentum", pos)

			pos = pos < 0 ? pos : 0

			if (pos < 0) {
				//if moving right
				let size = firstElement.current.offsetWidth //get width of slider
				let hiddenSlides = slides.length - slides_per_row
				let max = hiddenSlides * size

				console.log("max", max, "pos", pos)

				pos = pos * -1 > max ? max * -1 : pos
			}

			setDragPos(pos)
		}
	}

	return (
		<div className={`slider ${prefix !== "slider" && `${prefix}`} ${fade ? "fade" : "swipe"}`}>
			<div className={`slider-wrapper no-wrap row align-items-center col-fluid ${prefix !== "slider" && `${prefix}-wrapper`} ${draggable && "draggable"}`}>
				{arrows && (
					<div className={`slider-arrow-container row align-items-stretch justify-content-space-between col-fluid ${prefix !== "slider" && `${prefix}-arrow-container`}`}>
						<div
							className={`slider-arrow slider-arrow--left ${prefix !== "slider" && `${prefix}-arrow ${prefix}-arrow--left`}`}
							onClick={() =>
								setCurrentIndex((prev) => {
									return prev - 1 < 0 ? Math.floor(slides_per_row / slides_per_row) : prev - 1
								})
							}
						>
							<FontAwesomeIcon icon={faArrowLeft} />
						</div>

						<div
							className={`slider-arrow slider-arrow--right ${prefix !== "slider" && `${prefix}-arrow ${prefix}-arrow--right`}`}
							onClick={() =>
								setCurrentIndex((prev) => {
									return prev + 1 >= slides.length / slides_per_row ? 0 : prev + 1
								})
							}
						>
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
					</div>
				)}

				<div className={`slider-container ${prefix !== "slider" && `${prefix}-container`}`}>
					<div
						className={`slider-track row no-wrap ${prefix !== "slider" && `${prefix}-track`}`}
						style={{
							left: draggable ? dragPos + "px" : currentIndex * -100 + "%",
							"--slides-per-row": slides_per_row
						}}
						onMouseDown={handleMouseDown}
						onMouseUp={handleMouseUp}
						onMouseLeave={handleMouseUp}
						onMouseMove={handleDrag}
						onTouchStart={handleTouchStart}
						onTouchEnd={handleTouchEnd}
						onTouchMove={handleTouchDrag}
					>
						{slides &&
							slides.map((slide, index) => {
								return (
									<div
										key={index}
										className={`slider-item row column ${prefix !== "slider" && `${prefix}-item`} ${slides_per_row === 1 && currentIndex === index ? "active" : ""}`}
										ref={index == 0 ? firstElement : null}
									>
										{slide.image && (
											<img
												src={slide.image}
												alt={slide.title !== undefined ? slide.title : "Image"}
												className={`slider-item__img col-fluid ${prefix !== "slider" && `${prefix}-item__img`}`}
											/>
										)}

										{slide.element && slide.element}

										{slide.show_text && (
											<div className={`${prefix}-item__text ${prefix !== "slider" && `${prefix}-item__text`}`}>
												{slide.title}
												<div className="btn view-range-button">
													View Range <FontAwesomeIcon icon={faArrowRight} />
												</div>
											</div>
										)}
									</div>
								)
							})}
					</div>
				</div>
			</div>
			{indicators && (
				<div className={`slider-indicators-container row align-items-center justify-content-center col-fluid ${prefix !== "slider" && `${prefix}-indicators-container`}`}>
					{[...Array(Math.ceil(slides.length / slides_per_row))].map((slide, index) => {
						return (
							<div
								key={index}
								className={`slider-indicator ${prefix !== "slider" && `${prefix}-indicator`}` + (index == currentIndex ? " active" : "")}
								onClick={() => setCurrentIndex(index)}
							></div>
						)
					})}
				</div>
			)}
		</div>
	)
}
