import { RouterProvider, useLocation, Outlet } from "react-router-dom"
import "../src/assets/scss/base.scss"

import { Home, RoutesComponent } from "./pages"
import { Header, Footer } from "./components"

const Root = () => {
	const location = useLocation()
	return (
		<div className="App">
			<div
				className="main-header"
				id="main-header"
			>
				<Header />
			</div>
			<div
				className="main-content"
				id="main-content"
			>
				<Outlet key={location.pathname} />
			</div>
			<div
				className="main-footer"
				id="main-footer"
			>
				<Footer />
			</div>
		</div>
	)
}

function App() {
	return <RouterProvider router={RoutesComponent({ RootElement: Root })} />
}

export default App
