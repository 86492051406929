import React from "react";

import "../assets/scss/components/_stats-section.scss";

export const StatsSection = () => {
	return (
		<section className="stats-section">
			<div className="container">
				<div className="row justify-content-space-between">
					<div className="col-12 col-md-8">
						<strong className="h3-style">
							No Industry Left Unturned,
							<br />
							Extensive Experience In All Fields
						</strong>
						<p>I have worked in the industry for over 8 years, and there is very little I haven't encountered. From E-commerce shops to housing charities and recruitment platforms, I have a quite a range in my portfolio. I am convinced that I would have the expertise to help you with any kind of project you require. </p>
						<p>Starting professionally in 2015 I have covered contract and agency work, full project development and leading in-house development teams.</p>
					</div>
					<div className="col-12 col-md-4 stats-section">
						<div className="stats">
							<div className="stat">
								<span>2015</span>
								Professionally Started
							</div>
							<div className="stat">
								<span>4</span>
								Awards Won
							</div>
							<div className="stat">
								<span>30+</span>
								Websites Produced
							</div>
						</div>
					</div>
					<div className="col-12 usps">
						<div className="row justify-content-space-between">
							<div className="col-12 col-md-4">
								<div className="usp">
									<strong>Fully Bespoke Code</strong>
									<p>This is to ensure that everything is maintainable and there's not gonna be any nasty snags in the future when libraries get updated.</p>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="usp">
									<strong>Highly Optimised Websites</strong>
									<p>All sites go through strict optimisation before going live. Every sites launches with near perfect scores pagespeed scores.</p>
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="usp">
									<strong>From Initial Thought To Launch</strong>
									<p>Very strong experience in the whole site launch process, from original concepts & design all the way through to going live and site maintenance.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
