import React from "react"

import "../assets/scss/components/_footer.scss"

import Logo from "../assets/images/logo.png"

import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCodepen, faLinkedin } from "@fortawesome/free-brands-svg-icons"

export const Footer = () => {
	return (
		<div className="footer">
			<div className="container">
				<div className="row justify-content-space-between">
					<div className="col-3 col-lg-1 logo">
						<img
							src={Logo}
							alt="Logo"
						/>
					</div>

					<div className="footer-nav">
						{/* <Link to="/">About</Link> */}
						{/* <Link to="/">Projects</Link> */}
						{/* <Link to="/">Contact</Link> */}
					</div>

					<div className="col-1 socials">
						<Link
							to="https://codepen.io/ollypittaway"
							target="_blank"
							aria-label="CodePen"
						>
							<FontAwesomeIcon icon={faCodepen} />
						</Link>
						<Link
							to="https://www.linkedin.com/in/olly-pittaway/"
							target="_blank"
							aria-label="LinkedIn"
						>
							<FontAwesomeIcon icon={faLinkedin} />
						</Link>
					</div>
				</div>
				<div className="lower-footer">
					<div className="row justify-content-center">
						<div className="copy">&copy; Olly Pittaway {new Date().getFullYear()}</div>
					</div>
				</div>
			</div>
		</div>
	)
}
