import React from "react"

import "../assets/scss/components/_skillset.scss"

export const Skillset = () => {
	return (
		<section className="skillset">
			<div className="container">
				<strong className="h3-style">A Quick Breakdown Of Our Skills</strong>
				<hr />
				<div className="row">
					<div className="col-12 col-md-4">
						<strong>Design & Development</strong>
						<ul>
							<li>HTML</li>
							<li>PHP</li>
							<li>SCSS/CSS</li>
							<li>JavaScript</li>
							<li>React.js</li>
							<li>SQL</li>
							<li>Python</li>
							<li>Adobe Suite</li>
							<li>Figma</li>
							<li>Redux</li>
							<li>GSAP</li>
							<li>Three.js</li>
						</ul>
					</div>
					<div className="col-12 col-md-4">
						<strong>General IT Skills</strong>
						<ul>
							<li>Continous Integration/Development</li>
							<li>Project Management</li>
							<li>Unit Testing</li>
							<li>Code Optimisation</li>
							<li>Website Accessibility</li>
							<li>Google Analytics</li>
						</ul>
					</div>
					<div className="col-12 col-md-4">
						<strong>Interpersonal</strong>
						<ul>
							<li>Developer Coaching</li>
							<li>Team Leading</li>
							<li>Agile Workflow</li>
							<li>Autonomous Worker</li>
							<li>Strong Communication Skills</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	)
}
